<template>
  <div
    class="views-page is-flex is-flex-direction-column p-5"
    style="min-height: 50vh"
  >
    <div
      class="info-line"
      :class="GetMessagesData.main_color"
    />
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    />
    <div
      v-if="!isLoading && messages.length === 0"
      class="is-flex is-align-items-center is-justify-content-center"
    >
      <div class="is-flex is-align-items-center">
        <font-awesome-icon :icon="['fas', 'fa-search']" class="mr-2"/>
        <span class="has-text-black" style="font-size: 18px">пусто...</span>
      </div>
    </div>
    <div
      class="columns message__row"
      v-for="msg in messages"
      :key="msg.id"
    >
      <div class="column is-one-quarter is-flex is-align-items-center">
        <div>
          {{ msg.date }}
        </div>
      </div>
      <div class="column">
        {{ msg.msg }}
      </div>
    </div>
    <section class="mt-auto">
      <b-pagination
        v-model="currentPage"
        :total="total"
        :per-page="10"
        order="is-centered"
        icon-pack="fas"
        @change="fetchMessages"
      >
        <template #previous="props">
          <b-pagination-button
            :page="props.page"
            @click="fetchMessages(currentPage-1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-left']" />
          </b-pagination-button>
        </template>
        <template #next="props">
          <b-pagination-button
            :page="props.page"
            @click="fetchMessages(currentPage+1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-right']" />
          </b-pagination-button>
        </template>
      </b-pagination>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: ["device_id"],
  data() {
    return {
      offset: 0,
      messages: [],
      isLoading: true,
      currentPage: 1,
      total: 1,
    };
  },
  methods: {
    ...mapActions(["FetchArchiveMsg", "FetchMessages"]),
    ...mapMutations(["ChangeNavName"]),
    async fetchMessages(page) {
      this.currentPage = page || 1;
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/messages/${(this.currentPage - 1) * 10}/10`);
        this.messages = data?.data || [];
        this.total = data?.count || 1;
      } catch (e) {
        this.isLoading = false;
        throw new Error(e);
      }
      this.isLoading = false;
    },
  },
  created() {
    // this.ChangeNavName("Архивные сообщения")
    this.FetchMessages(this.device_id);
    this.fetchMessages();
  },
  computed: mapGetters(["GetMessagesData"]),
};
</script>

<style scoped>
.message__row:not(:nth-last-child(2)) {
  border-bottom: 1px solid #b3b3b3;
}
</style>
